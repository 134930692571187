/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import "./advantageYd.less";
import mingshiweike from "../../images/icon_minshiweikeYd.png";
import tiku from "../../images/icon_tikuYd.png";
import shuzipingtai from "../../images/icon_shuzipingtaiYd.png";
import jiaoyanfuwu from "../../images/icon_jiaoyanfuwuYd.png";


const AdvantageYd = () => (
  <div className='advantageContainerYd'>
    <div className='advantageListYd'>
      <dl className='advantageItemYd firstRow'>
        <dt>
          <img src={mingshiweike} alt={"精品微课"}/>
        </dt>
        <dd>
          <h4>成体系精品微课</h4>
          <p>约20万节，课程覆盖9个学科，3级难度。</p>
        </dd>
      </dl>
      <dl className='advantageItemYd firstRow itemYdFloat'>
        <dt>
          <img src={tiku} alt={"题库"}/>
        </dt>
        <dd>
          <h4>结构化题库</h4>
          <p>例题同类题精准配套。</p>
        </dd>
      </dl>
    </div>
    <div className='advantageListYd'>

      <dl className='advantageItemYd'>
        <dt>
          <img src={shuzipingtai} alt={"数字平台"}/>
        </dt>
        <dd>
          <h4>精准数字平台</h4>
          <p>学生端、教师端软件、学生端psd，一个区域微云服务器系统。</p>
        </dd>
      </dl>
      <dl className='advantageItemYd itemYdFloat'>
        <dt>
          <img src={jiaoyanfuwu} alt={"持续教研服务"}/>
        </dt>
        <dd>
          <h4>持续教研服务</h4>
          <p>保持日常沟通，随时电话咨询指导。</p>
        </dd>
      </dl>
    </div>
  </div>
);
export default AdvantageYd;
