/**
 * create by wangchunyan1 on 2019/8/7
 */
import React from "react";
import "./teachery.less";

import LayoutYd from "../components/layoutYd";
import AdvantageYd from "../components/advantage/advantageYd";
import TipsYd from "../components/tips/tipsYd";
import ContactUsYd from "../components/contactUs/contactUsYd";
import SlideImg from "../components/slideImgs/slideImg";

import teacherBanner from "../images/banner_teacher.jpg";
import operationPoster from "../images/pic_operationPoster.png";
import teacherInterviewPoster from "../images/pic_teacherInterviewPoster.png";
import studentInterviewPoster from "../images/pic_studentInterviewPoster.png";


import { Video } from "../utils/constant";

class TeacherY extends React.Component {

  render() {
    return (
      <LayoutYd>
        <div className='teacherBannerBox'>
          <img src={teacherBanner} alt='老师'/>
        </div>
        <div className='advantage'>
          <h3 className='commonTitleH3Yd'>我们的优势</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <table className='advantageTable'>
            <tbody>
            <tr>
              <th className='title'></th>
              <th className='zkt first'>智课堂备课</th>
              <th className='traditionClass'>传统课堂</th>
            </tr>
            <tr>
              <td className='title'>
                备课<br/>
                优势
              </td>
              <td className='zkt'>时间少。</td>
              <td className='traditionClass'>时间长。</td>
            </tr>
            <tr>
              <td className='title'>
                上课<br/>
                优势
              </td>
              <td className='zkt'>
                <p>
                  借助精品微课,实现分层教学；
                </p>
                <p>
                  借助系统界面,了解学生学习情况;
                </p>
                <p>
                  投屏展示:选取代表性强。
                </p>
              </td>
              <td className='traditionClass'>
                <p>统一难度层次教学；</p>
                <p>眼神揣测，把握程度不高；</p>
                <p>学生板书：代表性不强。</p>
              </td>
            </tr>
            <tr>
              <td className='title'>
                作业<br/>
                优势
              </td>
              <td className='zkt'>
                <p>
                  客观题系统自判；
                </p>
                <p>
                  主观题老师抽查改判；
                </p>
                <p>
                  批改时间节省50%。
                </p>
              </td>
              <td className='traditionClass'>
                <p>老师手动批改，工作量巨大；</p>
                <p>不能及时掌握作业的正答率。</p>
              </td>
            </tr>
            <tr>
              <td className='title'>
                课堂<br/>
                测试
              </td>
              <td className='zkt'>
                <p>
                  系统自动推荐选题，
                  组卷方便省力。
                </p>
              </td>
              <td className='traditionClass'>
                <p>翻阅海量资料，组卷判卷耗时费力。</p>
              </td>
            </tr>
            <tr>
              <td className='title'>
                个性<br/>
                清错
              </td>
              <td className='zkt'>
                <p>
                  老师一键操作，系统为每位学生生成个性化清错试卷。
                </p>

              </td>
              <td className='traditionClass'>
                <p>无或者难度极大。</p>
              </td>
            </tr>
            <tr>
              <td className='title'>
                学情<br/>
                掌控
              </td>
              <td className='zkt'>
                <p>
                  记录每个场景教与学的数据，
                </p>
                <p>
                  老师精准教，学生针对性的学。
                </p>
              </td>
              <td className='traditionClass'>
                <p>教与学缺乏针对性，</p>
                <p>总结错题，统一指导。</p>
              </td>
            </tr>
            <tr>
              <td className='title'>
                结学<br/>
                伴&nbsp;
              </td>
              <td className='zkt last'>
                <p>
                  学伴制（设定学习程度相当的同学作为学习目标和榜样，共同进步）。
                </p>
              </td>
              <td className='traditionClass'>
                <p>无</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='advantageList'>
          <h3 className='commonTitleH3Yd'>老师如何教，学生如何学</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <AdvantageYd/>
        </div>
        <div className='teacherOption'>
          <div className='titleBg'>
            <h3 className='title'>界面简单易于操作</h3>
            <p className='subTitle'>
              新老教师轻松上手
            </p>
          </div>
          <div className='teacherVideo'>
            <video className='ydVideo' preload="meta" controls controlsList='nodownload' poster={operationPoster} loop>
              <source src={Video.JieGouKe}
                      type="video/mp4"/>
              你的浏览器不支持 html5 video播放
            </video>
          </div>
        </div>
        <div className='supportService'>
          <div className='title'>
            <h3 className='commonTitleH3Yd'>
              提供全程支持服务<br/>
              定期分享使用案例
            </h3>
            <h4 className='teacherSectionServiceTitle'>
              简单智课堂拥有一套持续的教学教研支持服务，老师们在使用过程中遇到任何需求或问题，教学支持会快速反馈，协助解决。定期邀请优秀使用老师分享个人经验，共同进步。
            </h4>
          </div>
          <div className="serviceImagesBoxYd">
            <SlideImg from={"page-teachery"}/>
          </div>
        </div>
        <div className='feedback'>
          <h3 className='commonTitleH3Yd'>教师反馈</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <div>
            <video className='ydVideo' preload="meta" controls controlsList='nodownload' poster={teacherInterviewPoster} loop>
              <source src={Video.TeacherInterview}
                      type="video/mp4"/>
              你的浏览器不支持 html5 video播放
            </video>
          </div>
        </div>
        <div className='contactUsYdContainer'>
          <ContactUsYd/>
        </div>
        <TipsYd/>
      </LayoutYd>
    );
  }
};
export default TeacherY;
